import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../store/store";
import {
  IAssessment,
  IAssessmentType,
  ICategory,
  ICategoryAveragesResult,
  ICriterion,
  IOption,
  IResponse,
  ITerminology,
} from "../../../interfaces/assessment.interface";
import {
  clearAssessmentList,
  finishAssessment,
  searchAssessment,
} from "../../../store/assessments/assessment.slice";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartItem,
  ChartOptions,
  ChartData,
} from "chart.js";
import { IoChevronBack, IoChevronForward } from "react-icons/io5";
import { setCategory } from "../../../store/assessments/assessment.slice";
import { getNextCategory } from "../../../util/categoryPagination.util";
import Header from "../../templates/header.component";
import { evaluateScore } from "../../../util/criteriaEvaluator.util";
import ProgressBar from "../../templates/progressBar.component";
import { listAssessmentType } from "../../../store/assessments/assessmentType.slice";
import { MdOutlineStoreMallDirectory } from "react-icons/md";
import { IUser } from "../../../interfaces/auth.interface";
import { getTerm } from "../../../util/terminology.util";
import {
  calculateAverageOfAnsweredCategories,
  calculateCategoryAverages,
  calculateOverallAverage,
  getCategoryAverage,
  hasCustomWeight,
} from "../../../util/assessmentScore.util";
import { setBreadcrumbs } from "../../../store/app/breadcrumbs.slice";
import Breadcrumb from "../../templates/breadcrumb.component";
// Register the necessary Chart.js components
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const PastAssessments = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const user: IUser = useSelector(
    (state: RootState) => state.auth.user as IUser
  );
  const [selectedAssessmentType, setSelectedAssessmentType] =
    useState<IAssessmentType | null>(null);
  const [selectedAssessment, setSelectedAssessment] =
    useState<IAssessment | null>(null);
  const [averageValuesPerCategory, setAverageValuesPerCategory] =
    useState<ICategoryAveragesResult | null>(null);
  const [overallAverage, setOverallAverage] = useState<number | null>(null);
  const [
    overallAverageOfAnsweredCategories,
    setOverallAverageOfAnsweredCategories,
  ] = useState<number | null>(null);
  const [isWeighted, setIsWeighted] = useState(false);
  const [chartData, setChartData] = useState<ChartData<"bar"> | null>(null);
  // const assessment: IAssessment = useSelector(
  //   (state: RootState) => state.assessment.customAssessment as IAssessment
  // );
  // const category: ICategory = useSelector(
  //   (state: RootState) => state.assessment.category as ICategory
  // );

  const assessmentTypes = useSelector(
    (state: RootState) =>
      (state.assessmentType.assessmentTypeList as IAssessmentType[]) ?? []
  );
  const assessments = useSelector(
    (state: RootState) =>
      (state.assessment.assessmentList as IAssessment[]) ?? []
  );

  useEffect(() => {
    dispatch(
      setBreadcrumbs([
        { name: "Home", path: "/" },
        {
          name: "Past Assessments",
          path: "/past-assessments",
        },
      ])
    );
  }, [dispatch]);

  useEffect(() => {
    dispatch(listAssessmentType());
  }, [dispatch]);

  useEffect(() => {
    if (selectedAssessmentType && user) {
      dispatch(
        searchAssessment({
          assessment_type_id: selectedAssessmentType.assessment_type_id,
          company_id: user.company_id,
        })
      );
    } else {
      dispatch(clearAssessmentList());
    }
  }, [selectedAssessmentType]);

  useEffect(() => {
    if (averageValuesPerCategory) {
      setChartData({
        labels: averageValuesPerCategory.averages.map(
          (average) => average.name
        ),
        datasets: [
          {
            label: "Category Averages",
            data: averageValuesPerCategory.averages.map(
              (average) => average.value
            ),
            backgroundColor: averageValuesPerCategory.averages.map(
              (_, index) => colors[index % colors.length]
            ),
            borderColor: averageValuesPerCategory.averages.map(
              (_, index) => colors[index % colors.length]
            ),
            borderWidth: 1,
          },
        ],
      });

      if (!isWeighted) {
        setOverallAverage(calculateOverallAverage(averageValuesPerCategory));
        setOverallAverageOfAnsweredCategories(
          calculateAverageOfAnsweredCategories(averageValuesPerCategory)
        );
      } else {
        setOverallAverage(
          calculateOverallAverage(
            averageValuesPerCategory,
            (selectedAssessment as IAssessment).weights
          )
        );
        setOverallAverageOfAnsweredCategories(
          calculateAverageOfAnsweredCategories(
            averageValuesPerCategory,
            (selectedAssessment as IAssessment).weights
          )
        );
      }
    } else {
      setChartData(null);
      setOverallAverage(null);
      setOverallAverageOfAnsweredCategories(null);
    }
  }, [averageValuesPerCategory, isWeighted]);

  const colors = ["#24588c", "#3d8ec4", "#db5b44", "#de7e59"];

  const chartOptions: ChartOptions<"bar"> = {
    indexAxis: "y",
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          precision: 0,
        },
      },
    },
    plugins: {
      legend: {
        display: true,
      },
    },
    responsive: true,
    maintainAspectRatio: false,
  };

  const handleAssessmentTypeChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const selectedId = event.target.value;
    const assessmentType =
      assessmentTypes.find(
        (assessmentType) => assessmentType.assessment_type_id === selectedId
      ) || null;
    setSelectedAssessmentType(assessmentType);
    setSelectedAssessment(null);
    setAverageValuesPerCategory(null);
    setOverallAverage(null);
    setOverallAverageOfAnsweredCategories(null);
    setChartData(null);
  };
  const handleAssessmentChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const selectedId = event.target.value;
    const assessment =
      assessments.find(
        (assessment) => assessment.assessment_id === selectedId
      ) || null;
    setSelectedAssessment(assessment);
    setAverageValuesPerCategory(
      calculateCategoryAverages(assessment as IAssessment)
    );
  };

  const getResponseOption = (response: IResponse, options: IOption[]) => {
    const index = options.findIndex(
      (option) => response.option_id === option.option_id
    );

    if (index === -1) {
      return null;
    }
    return options[index];
  };

  const goHome = () => {
    navigate("/");
  };

  return (
    <div className="pb-4 h-screen flex flex-col justify-between">
      <Header />
      <div>
        <div className="px-4">
          <Breadcrumb />
        </div>
        <div className="px-4 pb-2 text-left flex flex-row justify-between">
          {/* <button className="px-4 py-2 mr-3" onClick={() => save(true)}>
          <IoChevronBack className="h-6 w-6" />
        </button> */}
          <p className="text-3xl font-bold">Past Assessments</p>
        </div>
      </div>

      <div className="mx-4 p-8 border-t border-cloc-blue">
        <div className="w-full xl:w-3/6">
          {/* Assessment Type Dropdown */}
          <div className="flex flex-col pb-2">
            <label
              htmlFor="Assessment Type"
              className="block pr-2 font-bold my-auto text-left"
            >
              Assessment Type
            </label>
            <select
              id="Assessment Type"
              name="Assessment Type"
              value={
                selectedAssessmentType
                  ? selectedAssessmentType.assessment_type_id
                  : ""
              }
              onChange={handleAssessmentTypeChange}
              className="block w-full p-1 border-2 border-gray-300 rounded-none focus:outline-none focus:ring-0 focus:border-gray-500 sm:text-sm w-5/6 text-left"
            >
              <option value="">Select Assessment Type</option>
              {assessmentTypes.map((assessmentType) => (
                <option
                  key={assessmentType.assessment_type_id}
                  value={assessmentType.assessment_type_id}
                >
                  {assessmentType.name}
                </option>
              ))}
            </select>
          </div>
          {/* Question Dropdown */}
          <div className="flex flex-col pb-2">
            <label
              htmlFor="Assessment Type"
              className="block pr-2 font-bold my-auto text-left"
            >
              Assessment
            </label>
            <select
              id="Assessment Type"
              name="Assessment Type"
              value={selectedAssessment ? selectedAssessment.assessment_id : ""}
              onChange={handleAssessmentChange}
              className="block w-full p-1 border-2 border-gray-300 rounded-none focus:outline-none focus:ring-0 focus:border-gray-500 sm:text-sm w-5/6 text-left"
            >
              <option value="">Select Assessment</option>
              {assessments.map((assessment) => (
                <option
                  key={assessment.assessment_id}
                  value={assessment.assessment_id}
                >
                  {assessment.name}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>

      <div className="pt-1 pb-2 h-full">
        {/* Summary */}
        {selectedAssessment?.assessment_type?.type === "scoring" ? (
          <div>
            {selectedAssessment &&
            hasCustomWeight(selectedAssessment as IAssessment) ? (
              <div className="flex justify-end items-center my-auto px-4">
                <input
                  name="selectAll"
                  id="selectAll"
                  type="checkbox"
                  checked={isWeighted}
                  onChange={(e) => setIsWeighted(e.target.checked)}
                />
                <label className="ml-2 text-lg" htmlFor="selectAll">
                  Show Weighted
                </label>
              </div>
            ) : null}
            <div className="mx-4 grid grid-cols-1 lg:grid-cols-2 gap-2">
              {chartData ? (
                <div className="my-2 p-4 bg-white shadow-md rounded-lg">
                  <Bar
                    data={chartData as ChartData<"bar">}
                    options={chartOptions}
                  />
                </div>
              ) : null}

              <div className="my-2 grid grid-cols-1 gap-2">
                {/* Card 1 */}
                {selectedAssessment &&
                averageValuesPerCategory &&
                overallAverageOfAnsweredCategories ? (
                  <div className="p-4 bg-white shadow-md rounded-lg w-full flex flex-col justify-evenly">
                    <div className="pl-5 flex flex-column pt-2 pb-6">
                      <div className="w-4/6 text-left">
                        <p className="text-3xl font-medium">
                          Organization score based on areas assessed{" "}
                          <span className="font-bold">
                            (
                            {
                              averageValuesPerCategory.numberOfAnsweredCategories
                            }
                            /{averageValuesPerCategory.totalNumberOfCategories})
                          </span>
                        </p>
                      </div>
                      <div className="text-right w-2/6 px-5 flex justify-end">
                        <div className="flex-col flex my-auto">
                          <p className="text-4xl font-bold text-cloc-orange mx-auto">
                            {overallAverageOfAnsweredCategories.toFixed(2)}
                          </p>
                          <p className="font-base text-sm mx-auto">
                            {
                              evaluateScore(
                                overallAverageOfAnsweredCategories,
                                selectedAssessment.assessment_type
                                  ?.criteria as ICriterion[]
                              )?.name
                            }
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="pb-5 px-5">
                      <ProgressBar
                        value={overallAverageOfAnsweredCategories}
                        criteria={
                          selectedAssessment.assessment_type
                            ?.criteria as ICriterion[]
                        }
                      />
                    </div>
                  </div>
                ) : null}

                {/* Card 2 */}
                {selectedAssessment && overallAverage ? (
                  <div className="p-4 bg-white shadow-md rounded-lg w-full flex flex-col justify-evenly">
                    <div className="pl-5 flex flex-column pt-2 pb-6">
                      <div className="w-4/6 text-left">
                        <p className="text-3xl font-medium">
                          Organization score based on all functional areas
                        </p>
                      </div>
                      <div className="text-right w-2/6 px-5 flex justify-end">
                        <div className="flex-col flex my-auto">
                          <p className="text-4xl font-bold text-cloc-orange mx-auto">
                            {overallAverage.toFixed(2)}
                          </p>
                          <p className="font-base text-sm mx-auto">
                            {
                              evaluateScore(
                                overallAverage,
                                selectedAssessment.assessment_type
                                  ?.criteria as ICriterion[]
                              )?.name
                            }
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="pb-5 px-5">
                      <ProgressBar
                        value={overallAverage}
                        criteria={
                          selectedAssessment.assessment_type
                            ?.criteria as ICriterion[]
                        }
                      />
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        ) : null}
        {/* Actions and Tasks */}
        {selectedAssessment ? (
          <div>
            {selectedAssessment.assessment_type?.categories.map(
              (category, index) => (
                <div key={index} className="p-4 w-full flex-col flex">
                  <div className="py-2 w-full bg-cloc-blue text-white">
                    <div className="flex justify-between">
                      <p className="text-left text-3xl font-medium w-full px-2">
                        {category.name} Matrix
                      </p>
                      <p className="text-right text-3xl font-medium w-full px-2">
                        {getCategoryAverage(
                          category.category_id,
                          averageValuesPerCategory as ICategoryAveragesResult
                        )}
                      </p>
                    </div>
                  </div>
                  <table className="w-full text-left">
                    <thead>
                      <tr className="text-sm bg-gray-300">
                        <th className="w-1/6 p-2">
                          {getTerm(
                            "Question",
                            selectedAssessment.assessment_type
                              ?.terminologies as ITerminology[]
                          )}
                        </th>
                        <th className="w-1/3 p-2">
                          {getTerm(
                            "Option",
                            selectedAssessment.assessment_type
                              ?.terminologies as ITerminology[]
                          )}
                        </th>
                        <th className="w-1/3 p-2">
                          {getTerm(
                            "Detail",
                            selectedAssessment.assessment_type
                              ?.terminologies as ITerminology[]
                          )}
                        </th>
                      </tr>
                    </thead>
                    <tbody className="px-2">
                      {category.questions.map((question, index) => (
                        <tr key={index} className="border-b-2 border-cloc-blue">
                          <td className="p-2">{question.text}</td>
                          <td className="p-2">
                            {getResponseOption(
                              question.response,
                              question.options
                            )?.text ?? ""}
                          </td>
                          <td className="p-2">
                            {getResponseOption(
                              question.response,
                              question.options
                            )?.detail ?? ""}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )
            )}
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default PastAssessments;
