import React, { useEffect, useState } from "react";
import {
  finishAssessment,
  setCategory,
} from "../../../store/assessments/assessment.slice";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../store/store";
import {
  IAssessment,
  ICategory,
  ICategoryAveragesResult,
  ICriterion,
  IOption,
  IQuestion,
  ITerminology,
  IWeight,
} from "../../../interfaces/assessment.interface";
import Header from "../../templates/header.component";
import { IoChevronBack } from "react-icons/io5";
import { evaluateScore } from "../../../util/criteriaEvaluator.util";
import { getTerm } from "../../../util/terminology.util";
import {
  calculateCategoryAverages,
  calculateOverallAverage,
} from "../../../util/assessmentScore.util";
import { FaSave } from "react-icons/fa";
import Breadcrumb from "../../templates/breadcrumb.component";
import { setBreadcrumbs } from "../../../store/app/breadcrumbs.slice";

const ActionAndTask = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const assessment: IAssessment = useSelector(
    (state: RootState) => state.assessment.customAssessment as IAssessment
  );
  const category: ICategory = useSelector(
    (state: RootState) => state.assessment.category as ICategory
  );
  const [selectedCategory, setSelectedCategory] = useState<ICategory | null>(
    null
  );
  const [selectedQuestion, setSelectedQuestion] = useState<IQuestion | null>(
    null
  );
  const [selectedOption, setSelectedOption] = useState<IOption | null>(null);
  const [selectedCriterion, setSelectedCriterion] = useState<ICriterion | null>(
    null
  );
  const [averageValuesPerCategory, setAverageValuesPerCategory] =
    useState<ICategoryAveragesResult>(
      calculateCategoryAverages(assessment) ?? {
        averages: [],
        numberOfAnsweredCategories: 0,
        totalNumberOfCategories: 0,
      }
    );

  useEffect(() => {
    dispatch(
      setBreadcrumbs([
        { name: "Home", path: "/" },
        {
          name: assessment.assessment_type!.name,
          path: "/assessment/category",
        },
        {
          name: "Actions and Tasks",
          path: "/assessment/summary/action-and-task",
        },
      ])
    );
  }, [dispatch]);

  useEffect(() => {
    if (!assessment) {
      navigate("/");
    }
  }, [assessment, navigate]);

  const handleCategoryChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const selectedId = event.target.value;
    const category =
      assessment.assessment_type?.categories.find(
        (cat) => cat.category_id === selectedId
      ) || null;
    setSelectedCategory(category);
    setSelectedQuestion(null);
    setSelectedOption(null);
    setSelectedCriterion(null);
  };

  const handleQuestionChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const selectedId = event.target.value;
    const question =
      selectedCategory?.questions.find(
        (question) => question.question_id === selectedId
      ) || null;
    setSelectedQuestion(question);
    setSelectedOption(null);

    const response =
      question?.options.find(
        (option) => option.option_id === question.response.option_id
      ) || null;
    setSelectedOption(response);

    const criterion = evaluateScore(
      response?.value as number,
      assessment.assessment_type?.criteria as ICriterion[]
    );
    setSelectedCriterion(criterion);
  };

  const goToAssess = () => {
    navigate("/assessment/summary");
  };

  const goToCategory = (cat: ICategory, prev: boolean) => {
    if (!prev) {
      // dispatch(setCategory(null));
      dispatch(setCategory(cat));
    }
    navigate("/assessment/category/take");
  };

  const finish = (assessment: IAssessment) => {
    dispatch(
      finishAssessment({
        id: assessment.assessment_id as string,
        score: calculateOverallAverage(averageValuesPerCategory),
        weighted_score: calculateOverallAverage(
          averageValuesPerCategory,
          assessment.weights
        ),
      })
    ).then(() => {
      // TODO: Handle negative flow
      navigate("/assessment/success");
    });
  };

  return (
    <div className="pb-4 h-screen flex flex-col justify-between">
      <Header />
      <div>
        <div className="px-4">
          <Breadcrumb />
        </div>
        <div className="px-4 pb-2 text-left flex flex-row justify-between">
          <div className="flex">
            {/* <button className="px-4 py-2 mr-3" onClick={() => save(true)}>
          <IoChevronBack className="h-6 w-6" />
        </button> */}
            <p className="text-3xl font-bold">
              {assessment.assessment_type?.name} Actions and Tasks
            </p>
          </div>
          <div className="my-auto justify-end">
            <button
              className="px-4 py-2 bg-cloc-blue text-white rounded-full mr-3"
              onClick={() => goToAssess()}
            >
              Summary
            </button>
          </div>
        </div>
      </div>

      <div className="mx-4 px-0 lg:px-8 py-8 border-t border-cloc-blue flex flex-col lg:flex-row justify-between pt-1 pb-2 h-full">
        <div className="px-0 md:px-4 py-4 bg-white w-full">
          {/* Form Card */}
          <div className="w-full">
            {/* Category Dropdown */}
            <div className="flex flex-col pb-2">
              <label
                htmlFor="category_id"
                className="block pr-2 font-bold my-auto text-left"
              >
                {getTerm(
                  "Category",
                  assessment.assessment_type?.terminologies as ITerminology[]
                )}
              </label>
              <select
                id="category_id"
                name="category_id"
                value={selectedCategory ? selectedCategory.category_id : ""}
                onChange={handleCategoryChange}
                className="block w-full p-1 border-2 border-gray-300 rounded-none focus:outline-none focus:ring-0 focus:border-gray-500 sm:text-sm w-5/6 text-left"
              >
                <option value="">
                  Select{" "}
                  {getTerm(
                    "Category",
                    assessment.assessment_type?.terminologies as ITerminology[]
                  )}
                </option>
                {assessment.assessment_type?.categories.map((category) => (
                  <option
                    key={category.category_id}
                    value={category.category_id}
                  >
                    {category.name}
                  </option>
                ))}
              </select>
            </div>
            {/* Question Dropdown */}
            <div className="flex flex-col py-2">
              <label
                htmlFor="question_id"
                className="block pr-2 font-bold my-auto text-left"
              >
                {getTerm(
                  "Question",
                  assessment.assessment_type?.terminologies as ITerminology[]
                )}
              </label>
              <select
                id="question_id"
                name="question_id"
                value={selectedQuestion ? selectedQuestion.question_id : ""}
                onChange={handleQuestionChange}
                className="block w-full p-1 border-2 border-gray-300 rounded-none focus:outline-none focus:ring-0 focus:border-gray-500 sm:text-sm w-5/6 text-left"
              >
                <option value="">
                  Select{" "}
                  {getTerm(
                    "Question",
                    assessment.assessment_type?.terminologies as ITerminology[]
                  )}
                </option>
                {selectedCategory?.questions.map((question) => (
                  <option
                    key={question.question_id}
                    value={question.question_id}
                  >
                    {question.text}
                  </option>
                ))}
              </select>
            </div>
            {/* Response */}
            <div className="flex flex-col py-2">
              <label
                htmlFor="response"
                className="block pr-2 font-bold my-auto text-left"
              >
                {getTerm(
                  "Response",
                  assessment.assessment_type?.terminologies as ITerminology[]
                )}
              </label>
              <textarea
                id="response"
                name="response"
                value={selectedOption?.text || ""}
                className="block w-full p-2 border-2 border-gray-300 rounded-none focus:outline-none focus:ring-0 focus:border-gray-500 sm:text-sm w-5/6 text-left"
                readOnly
              />
            </div>
            {/* Maturity State */}
            <div className="flex flex-col py-2">
              <label
                htmlFor="state"
                className="block pr-2 font-bold my-auto text-left"
              >
                {getTerm(
                  "Classification",
                  assessment.assessment_type?.terminologies as ITerminology[]
                )}
              </label>
              <div>
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
                  {assessment?.assessment_type?.criteria?.map((criterion) => (
                    <div
                      className={`border-2 border-gray-300 rounded-none py-1 px-2 ${
                        selectedCriterion === criterion
                          ? "bg-cloc-orange text-white border-cloc-orange"
                          : "bg-white text-black border-gray-300"
                      }`}
                      key={criterion.criterion_id}
                    >
                      <h3 className="text-sm font-medium my-auto">
                        {criterion.name}
                      </h3>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            {/* Recommendation */}
            <div className="flex flex-col py-2">
              <label
                htmlFor="detail"
                className="block pr-2 font-bold my-auto text-left"
              >
                {getTerm(
                  "Detail",
                  assessment.assessment_type?.terminologies as ITerminology[]
                )}
              </label>
              <textarea
                id="detail"
                name="detail"
                value={selectedOption?.detail || ""}
                className="block w-full p-2 border-2 border-gray-300 rounded-none focus:outline-none focus:ring-0 focus:border-gray-500 sm:text-sm w-5/6 text-left"
                readOnly
              />
            </div>
          </div>
        </div>
        <div className="hidden md:block px-0 md:px-4 py-4 w-full flex-col flex">
          <div className="w-1/2 text-left py-2 w-full bg-cloc-blue text-white">
            <p className="text-3xl font-medium w-full px-2">
              {selectedCategory?.name} Matrix
            </p>
          </div>
          <table className="w-full text-left">
            <thead>
              <tr className="text-sm bg-gray-300">
                <th className="w-1/6 p-2">
                  {getTerm(
                    "Category",
                    assessment.assessment_type?.terminologies as ITerminology[]
                  )}
                </th>
                <th className="w-1/6 p-2">
                  {getTerm(
                    "Classification",
                    assessment.assessment_type?.terminologies as ITerminology[]
                  )}
                </th>
                <th className="w-1/3 p-2">
                  {getTerm(
                    "Option",
                    assessment.assessment_type?.terminologies as ITerminology[]
                  )}
                </th>
                <th className="w-1/3 p-2">
                  {getTerm(
                    "Detail",
                    assessment.assessment_type?.terminologies as ITerminology[]
                  )}
                </th>
              </tr>
            </thead>
            <tbody className="px-2">
              {selectedQuestion?.options.map((option, index) => (
                <tr
                  key={option.option_id}
                  className={`border-b-2 border-cloc-blue ${
                    option.option_id === selectedQuestion.response.option_id
                      ? "bg-cloc-blue text-white"
                      : "text-black bg-white"
                  }`}
                >
                  <td className="p-2">
                    {index == 0 ? selectedQuestion.text : ""}
                  </td>
                  <td className="p-2">
                    {
                      evaluateScore(
                        option.value as number,
                        assessment.assessment_type?.criteria as ICriterion[]
                      )?.name
                    }
                  </td>
                  <td className="p-2">{option.text}</td>
                  <td className="p-2">{option.detail}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      {assessment.assessment_type?.type !== "scoring" ? (
        <div className="flex justify-between px-4 pt-4 pb-8">
          {category ? (
            <div className="flex">
              <button
                className="flex text-sm px-4 py-2 bg-cloc-blue text-white rounded-full hover:bg-cloc-blue-alt transition-colors"
                onClick={() => goToCategory(category, true)}
              >
                <IoChevronBack className="my-auto mr-2 h-4 w-4" />
                Previous
              </button>
              <p className="text-xs my-auto pl-3">{category.name}</p>
            </div>
          ) : (
            // Empty div to align next button
            <div></div>
          )}
          <button
            className="flex text-sm px-4 py-2 bg-cloc-orange text-white rounded-full hover:bg-cloc-orange-alt transition-colors"
            onClick={() => finish(assessment)}
          >
            Finish
            <FaSave className="my-auto ml-2 h-4 w-4" />
          </button>
        </div>
      ) : null}
    </div>
  );
};

export default ActionAndTask;
