// src/features/auth/authSlice.ts
import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import axios from "../../api/axios";
import {
  IAssessment,
  IAssessmentType,
  ICategory,
  IResponse,
  IWeight,
} from "../../interfaces/assessment.interface";
import { AxiosError } from "axios";
import { IUser } from "../../interfaces/auth.interface";

export interface AssessmentState {
  loading: boolean;
  error: string | null;
  overallAverage: number | null;
  assessment: IAssessment | null;
  customAssessment: IAssessment | null;
  category: ICategory | null;
  assessmentList: IAssessment[] | null;
}

interface UpdateResponsePayload {
  categoryId: string;
  questionId: string;
  optionId: string;
  answer: string;
}

const initialState: AssessmentState = {
  loading: false,
  error: null,
  overallAverage: null,
  assessment: null,
  customAssessment: null,
  category: null,
  assessmentList: null,
};

const getYearQuarter = (): number => {
  const currentMonth = new Date().getMonth(); // getMonth() returns 0-11
  return Math.floor(currentMonth / 3) + 1;
};

const getYear = (): number => {
  const currentDate = new Date();
  return currentDate.getFullYear();
};

const sortAssessmentData = (assessment: IAssessment) => {
  // Clone the assessment to avoid mutating the original object
  const sortedAssessment: IAssessment = JSON.parse(
    JSON.stringify(assessment)
  ) as IAssessment;

  // Sort criteria
  sortedAssessment.assessment_type?.criteria?.sort((a, b) => a.order - b.order);

  // Sort categories
  sortedAssessment.assessment_type?.categories?.sort(
    (a, b) => a.order - b.order
  );

  // Sort questions within each category
  sortedAssessment.assessment_type?.categories?.forEach((category) => {
    category.questions?.sort((a, b) => a.order - b.order);

    // Sort options within each question (if it's a Multiple Choice question)
    category.questions?.forEach((question) => {
      if (question.options) {
        question.options.sort((a, b) => a.order - b.order);
      }
    });
  });

  return sortedAssessment;
};

export const createAssessment = createAsyncThunk(
  "assessment/create",
  async (
    data: { user: IUser; assessmentType: IAssessmentType }, //TODO: add company here
    { rejectWithValue }
  ) => {
    try {
      //TODO: add checker in backend that checks if there is a duplicate assessment for same company, same year, same quarter
      const request = {
        company_id: data.user.company_id,
        assessment_type_id: data.assessmentType.assessment_type_id,
        user_id: data.user.user_id,
        name: `CompanyName_${getYear()}_Q${getYearQuarter()}_${
          data.assessmentType.name
        }`,
        description: `Company Name's ${
          data.assessmentType.name
        } for the year ${getYear()}.`,
        year: getYear(),
        quarter: "Q" + getYearQuarter(),
        status: "Open",
      };
      const response = await axios.post("/assessments", request);
      return response.data;
    } catch (error) {
      if (!(error as AxiosError).response) {
        throw error;
      }
      return rejectWithValue(
        (error as AxiosError<{ message: string }>).response?.data?.message
      );
    }
  }
);

export const searchAssessment = createAsyncThunk(
  "assessment/search",
  async (
    data: IAssessment, //TODO: add company here
    { rejectWithValue }
  ) => {
    try {
      const response = await axios.post("/assessments/search", data);
      return response.data;
    } catch (error) {
      if (!(error as AxiosError).response) {
        throw error;
      }
      return rejectWithValue(
        (error as AxiosError<{ message: string }>).response?.data?.message
      );
    }
  }
);

export const getAssessmentAverage = createAsyncThunk(
  "assessment/average",
  async (
    data: { assessment_type_id: string; company_id: string },
    { rejectWithValue }
  ) => {
    try {
      const response = await axios.post("/assessments/average-score", data);
      return response.data;
    } catch (error) {
      if (!(error as AxiosError).response) {
        throw error;
      }
      return rejectWithValue(
        (error as AxiosError<{ message: string }>).response?.data?.message
      );
    }
  }
);

export const updateAssessment = createAsyncThunk(
  "assessment/update",
  async (data: IAssessment, { rejectWithValue }) => {
    try {
      const response = await axios.put(
        "/assessments/" + data.assessment_type_id,
        data
      );
      return response.data;
    } catch (error) {
      if (!(error as AxiosError).response) {
        throw error;
      }
      return rejectWithValue(
        (error as AxiosError<{ message: string }>).response?.data?.message
      );
    }
  }
);

export const finishAssessment = createAsyncThunk(
  "assessment/finish",
  async (
    data: { id: string; score: number; weighted_score: number },
    { rejectWithValue }
  ) => {
    try {
      const response = await axios.put("/assessments/" + data.id, {
        status: "Completed",
        score: data.score,
        weighted_score: data.weighted_score,
      });
      return response.data;
    } catch (error) {
      if (!(error as AxiosError).response) {
        throw error;
      }
      return rejectWithValue(
        (error as AxiosError<{ message: string }>).response?.data?.message
      );
    }
  }
);

export const archiveAssessment = createAsyncThunk(
  "assessment/archive",
  async (data: IAssessment, { rejectWithValue }) => {
    try {
      const response = await axios.delete(
        "/assessments/" + data.assessment_type_id
      );
      return response.data;
    } catch (error) {
      if (!(error as AxiosError).response) {
        throw error;
      }
      return rejectWithValue(
        (error as AxiosError<{ message: string }>).response?.data?.message
      );
    }
  }
);

export const listAssessment = createAsyncThunk(
  "assessment/list",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get("/assessments");
      return response.data;
    } catch (error) {
      if (!(error as AxiosError).response) {
        throw error;
      }
      return rejectWithValue(
        (error as AxiosError<{ message: string }>).response?.data?.message
      );
    }
  }
);

export const fetchAssessment = createAsyncThunk(
  "assessment/fetch",
  async (data: IAssessment, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        "/assessments/" + data.assessment_type_id
      );
      return response.data;
    } catch (error) {
      if (!(error as AxiosError).response) {
        throw error;
      }
      return rejectWithValue(
        (error as AxiosError<{ message: string }>).response?.data?.message
      );
    }
  }
);

export const saveResponses = createAsyncThunk(
  "assessment/saveResponses",
  async (
    payload: { assessment_id: string; responses: IResponse[] },
    { getState, rejectWithValue }
  ) => {
    const { assessment_id, responses } = payload;
    try {
      const response = await axios.post("/responses/save", {
        assessment_id,
        responses,
      });
      return response.data;
    } catch (error) {
      if (!(error as AxiosError).response) {
        throw error;
      }
      return rejectWithValue(
        (error as AxiosError<{ message: string }>).response?.data?.message
      );
    }
  }
);

export const saveWeights = createAsyncThunk(
  "assessment/saveWeights",
  async (
    payload: { assessment_id: string; weights: IWeight[] },
    { getState, rejectWithValue }
  ) => {
    const { assessment_id, weights } = payload;
    try {
      const response = await axios.post("/weights/save", {
        assessment_id,
        weights,
      });
      return response.data;
    } catch (error) {
      if (!(error as AxiosError).response) {
        throw error;
      }
      return rejectWithValue(
        (error as AxiosError<{ message: string }>).response?.data?.message
      );
    }
  }
);

export const assessmentSlice = createSlice({
  name: "assessment",
  initialState,
  reducers: {
    setAssessment: (state, { payload }) => {
      state.assessment = sortAssessmentData(payload);
    },
    setCustomAssessment: (state, { payload }) => {
      state.customAssessment = sortAssessmentData(payload);
    },
    clearAssessment: (state) => {
      state.assessment = null;
    },
    clearCustomAssessment: (state) => {
      state.customAssessment = null;
    },
    clearAssessmentList: (state) => {
      state.assessmentList = null;
    },
    setCategory: (state, { payload }) => {
      state.category = payload;
    },
    updateResponse: (state, action: PayloadAction<UpdateResponsePayload>) => {
      const { categoryId, questionId, optionId } = action.payload;
      const category = state.assessment?.assessment_type?.categories?.find(
        (c) => c.category_id === categoryId
      );
      const question = category?.questions?.find(
        (q) => q.question_id === questionId
      );
      if (question) {
        question.response = { ...question.response, option_id: optionId };
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(createAssessment.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createAssessment.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.assessment = sortAssessmentData(payload);
    });
    builder.addCase(createAssessment.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload as string;
    });
    builder.addCase(updateAssessment.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateAssessment.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.assessment = sortAssessmentData(payload);
    });
    builder.addCase(updateAssessment.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload as string;
    });
    builder.addCase(archiveAssessment.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(archiveAssessment.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.assessment = sortAssessmentData(payload);
    });
    builder.addCase(archiveAssessment.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload as string;
    });
    builder.addCase(fetchAssessment.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchAssessment.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.assessment = sortAssessmentData(payload);
    });
    builder.addCase(fetchAssessment.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload as string;
    });
    builder.addCase(listAssessment.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(listAssessment.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.assessmentList = payload.map(sortAssessmentData);
    });
    builder.addCase(listAssessment.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload as string;
    });
    builder.addCase(saveResponses.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(saveResponses.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.assessment = sortAssessmentData(payload);
    });
    builder.addCase(saveResponses.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload as string;
    });
    builder.addCase(saveWeights.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(saveWeights.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.assessment = sortAssessmentData(payload);
    });
    builder.addCase(saveWeights.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload as string;
    });
    builder.addCase(finishAssessment.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(finishAssessment.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = null;
      // state.assessment = null;
      // state.category = null;
      // state.assessmentList = null;
    });
    builder.addCase(finishAssessment.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload as string;
    });
    builder.addCase(searchAssessment.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(searchAssessment.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.assessmentList = payload.map(sortAssessmentData);
    });
    builder.addCase(searchAssessment.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload as string;
    });
    builder.addCase(getAssessmentAverage.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAssessmentAverage.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.overallAverage = payload.average;
    });
    builder.addCase(getAssessmentAverage.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload as string;
    });
  },
});

export const {
  clearAssessment,
  clearCustomAssessment,
  setAssessment,
  setCategory,
  updateResponse,
  setCustomAssessment,
  clearAssessmentList,
} = assessmentSlice.actions;
export default assessmentSlice.reducer;
