import React, { useEffect, useRef, useState } from "react";
import { IRole, ISession, IUser } from "../../interfaces/auth.interface";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store/store";
import { useNavigate } from "react-router-dom";
import { getLoggedInUser, logout } from "../../store/auth/auth.slice";
import { IoClose } from "react-icons/io5";

const Header = () => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const defaultProfilePhoto =
    process.env.PUBLIC_URL + "/images/default-user.jpg";
  // const defaultProfilePhoto = process.env.PUBLIC_URL + "/images/boyd.png";
  const logo = process.env.PUBLIC_URL + "/logo512.png";
  const logoWhite = process.env.PUBLIC_URL + "/logo512-white.png";
  const user: IUser = useSelector(
    (state: RootState) => state.auth.user as IUser
  );
  const role: IRole = useSelector(
    (state: RootState) => state.role.role as IRole
  );
  const [isSidebarOpen, setSidebarOpen] = useState(false);

  const goHome = () => {
    setSidebarOpen(false);
    navigate("/");
  };

  const goToCompanies = () => {
    setSidebarOpen(false);
    navigate("/company");
  };

  const goToProfile = () => {
    setSidebarOpen(false);
    navigate("/profile");
  };

  const goToRoles = () => {
    setSidebarOpen(false);
    navigate("/role");
  };

  const toggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
  };

  return (
    <div className="relative bg-white">
      <nav className="text-white px-4 flex justify-between items-center">
        <img
          src={logo}
          alt="Logo"
          className="h-20 cursor-pointer"
          onClick={() => goHome()}
        />
        <div className="flex" onClick={toggleSidebar}>
          <div className="ml-2 my-auto text-black text-sm font-light pr-2 hidden md:block">
            {user?.first_name ?? ""} {user?.last_name ?? ""}{" "}
          </div>
          <img
            src={user?.photo || defaultProfilePhoto}
            alt="Profile"
            className="w-10 h-10 rounded-full cursor-pointer"
          />
        </div>
      </nav>
      <div
        className={`${
          isSidebarOpen ? "translate-x-0" : "translate-x-full"
        } fixed inset-0 bg-cloc-blue z-50 transition-transform`}
      >
        <div className="px-4 bg-gray-800 h-full">
          <div className="flex justify-center justify-between">
            <img
              src={logoWhite}
              alt="Logo"
              className="h-20 cursor-pointer"
              onClick={() => goHome()}
            />
            <button onClick={toggleSidebar} className="px-4 py-2">
              <IoClose className="text-white h-6 w-6" />
            </button>
          </div>
          <ul className="mt-4 pr-5 text-right font-bold text-xl">
            <li className="py-2">
              <a
                className="text-gray-300 hover:text-white cursor-pointer"
                onClick={() => goHome()}
              >
                Home
              </a>
            </li>
            <li className="py-2">
              <a
                className="text-gray-300 hover:text-white cursor-pointer"
                onClick={() => goToProfile()}
              >
                Profile
              </a>
            </li>
            <li
              className={`py-2 ${
                role!.name === "Administrator" ? "block" : "hidden"
              }`}
            >
              <a
                className="text-gray-300 hover:text-white cursor-pointer"
                onClick={() => goToCompanies()}
              >
                Companies
              </a>
            </li>
            <li
              className={`py-2 ${
                role!.name === "Administrator" ? "block" : "hidden"
              }`}
            >
              <a
                className="text-gray-300 hover:text-white cursor-pointer"
                onClick={() => goToRoles()}
              >
                Roles
              </a>
            </li>
            <li className="py-2">
              <a
                className="text-gray-300 hover:text-white cursor-pointer"
                onClick={() => dispatch(logout())}
              >
                Logout
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );

  //   return (
  //     <div className="flex justify-between">
  //       <div className="px-8 py-0">
  //         <img src={logo} alt="Logo" className="h-20 cursor-pointer" />
  //       </div>
  //       <div className="p-4 flex items-center">
  //         {/* User Info and Dropdown */}
  //   <div className="ml-2">
  //     {user?.first_name} {user.last_name}{" "}
  //   </div>
  //   <img
  //     src={user?.photo || defaultProfilePhoto}
  //     alt="Profile"
  //     className="w-10 h-10 rounded-full cursor-pointer"
  //     onClick={() => setDropdownOpen(!dropdownOpen)}
  //   />
  //         {dropdownOpen && (
  //           <div className="absolute right-0 mt-2 py-2 w-48 bg-white rounded-md shadow-xl z-20">
  //             <a
  //               href="#/"
  //               className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
  //               onClick={() => dispatch(logout())}
  //             >
  //               Logout
  //             </a>
  //             <a
  //               href="#/"
  //               className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
  //               onClick={() => goToCompanies()}
  //             >
  //               Companies
  //             </a>
  //             <a
  //               href="#/"
  //               className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
  //               onClick={() => goToRoles()}
  //             >
  //               Roles
  //             </a>
  //           </div>
  //         )}
  //       </div>
  //     </div>
  //   );
};

export default Header;
