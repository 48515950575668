// src/features/auth/authSlice.ts
import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import axios from "../../api/axios";
import { ISession, IUser } from "../../interfaces/auth.interface";
import { AxiosError, AxiosResponse } from "axios";
import { useDispatch } from "react-redux";
import { fetchRole, setRole } from "./role.slice";
import { AppDispatch } from "../store";

//TODO: secure storage of sessions. Encode your sessions in localStorage or find another way to store them
const getToken = () => localStorage.getItem("token") || null;
const getSession = () =>
  (JSON.parse(localStorage.getItem("session") as string) as ISession) || null;

export interface SessionUpdatePayload {
  token: string | null;
  session: ISession | null;
}

export interface AuthState {
  loading: boolean;
  error: string | null;
  token: string | null;
  isAuthenticated: boolean;
  session?: ISession | null;
  user?: IUser | null;
}

const initialState: AuthState = {
  loading: false,
  error: null,
  token: null,
  isAuthenticated: getToken() !== null,
  session: getSession(),
  user: null,
};

export const getLoggedInUser = createAsyncThunk(
  "auth/getUser",
  async (userId: string, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.get("/users/" + userId);
      dispatch(setRole(response.data!.role));
      return response.data;
    } catch (error) {
      if (!(error as AxiosError).response) {
        throw error;
      }
      return rejectWithValue(
        (error as AxiosError<{ message: string }>).response?.data?.message
      );
    }
  }
);

export const login = createAsyncThunk(
  "auth/login",
  async (
    userData: { email: string; password: string },
    { rejectWithValue, dispatch }
  ) => {
    try {
      const response = await axios.post("/users/login", userData);
      localStorage.setItem("token", response.data.session.access_token);
      localStorage.setItem("session", JSON.stringify(response.data.session));
      dispatch(setRole(response.data!.user.role));
      return response.data;
    } catch (error) {
      if (!(error as AxiosError).response) {
        throw error;
      }
      return rejectWithValue(
        (error as AxiosError<{ message: string }>).response?.data?.message
      );
    }
  }
);

export const refreshToken = createAsyncThunk(
  "auth/refreshToken",
  async (session: ISession, { rejectWithValue }) => {
    try {
      const response = await axios.post("/users/refresh-token", session);
      localStorage.setItem("token", response.data.access_token);
      localStorage.setItem("session", JSON.stringify(response.data.session));
      return response.data;
    } catch (error) {
      if (!(error as AxiosError).response) {
        throw error;
      }
      return rejectWithValue(
        (error as AxiosError<{ message: string }>).response?.data?.message
      );
    }
  }
);

export const logout = createAsyncThunk(
  "auth/logout",
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.post(
        "/users/logout",
        localStorage.getItem("token")
      );
      localStorage.removeItem("token");
      localStorage.removeItem("session");
      dispatch(setRole(null));
      return response.data;
    } catch (error) {
      if (!(error as AxiosError).response) {
        throw error;
      }
      return rejectWithValue(
        (error as AxiosError<{ message: string }>).response?.data?.message
      );
    }
  }
);

export const signup = createAsyncThunk(
  "auth/signup",
  async (userData: IUser, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.post("/users/signup", userData);
      localStorage.setItem("token", response.data.session.access_token);
      localStorage.setItem("session", JSON.stringify(response.data.session));
      dispatch(setRole(response.data!.user.role));
      return response.data;
    } catch (error) {
      if (!(error as AxiosError).response) {
        throw error;
      }
      return rejectWithValue(
        (error as AxiosError<{ message: string }>).response?.data?.message
      );
    }
  }
);

export const noviCallback = createAsyncThunk(
  "auth/noviCallback",
  async (data: { code: string; state: string }, { rejectWithValue }) => {
    try {
      const response = await axios.post("/users/auth/novi", data);
      localStorage.setItem("token", response.data.session.access_token);
      localStorage.setItem("session", JSON.stringify(response.data.session));
      return response.data;
    } catch (error) {
      if (!(error as AxiosError).response) {
        throw error;
      }
      return rejectWithValue(
        (error as AxiosError<{ message: string }>).response?.data?.message
      );
    }
  }
);

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setUser: (state, { payload }) => {
      state.user = payload;
    },
    updateSession: (state, action: PayloadAction<SessionUpdatePayload>) => {
      state.token = action.payload.token;
      state.session = action.payload.session;
      state.isAuthenticated = !!action.payload.token;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(login.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(login.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.token = payload.user.user_id;
      state.session = payload.session;
      state.error = null;
      state.user = payload.user;
    });
    builder.addCase(login.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload as string;
    });
    builder.addCase(logout.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(logout.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.token = null;
      state.session = null;
      state.error = null;
      state.user = null;
    });
    builder.addCase(logout.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload as string;
    });
    builder.addCase(refreshToken.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(refreshToken.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.session = payload;
      state.error = null;
    });
    builder.addCase(refreshToken.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload as string;
    });
    builder.addCase(signup.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(signup.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.token = payload.user.user_id;
      state.session = payload.session;
      state.error = null;
      state.user = payload.user;
    });
    builder.addCase(signup.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload as string;
    });
    builder.addCase(noviCallback.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(noviCallback.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.token = payload.user.user_id;
      state.session = payload.session;
      state.error = null;
      state.user = payload.user;
    });
    builder.addCase(noviCallback.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload as string;
    });
    builder.addCase(getLoggedInUser.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getLoggedInUser.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.user = payload;
    });
    builder.addCase(getLoggedInUser.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload as string;
    });
  },
});

export const { setUser, updateSession } = authSlice.actions;
export default authSlice.reducer;
