import React, { useEffect } from "react";
import logo from "./logo.svg";
import "./App.css";
import { Routes, Route, Navigate } from "react-router-dom";
import Login from "./components/portal/login.component";
import Home from "./components/home/home.component";
import ChooseCategory from "./components/assessments/user/chooseCategory.component";
import NotFound from "./components/exception/notFound.component";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "./store/store";
import Question from "./components/assessments/user/question.component";
import Summary from "./components/assessments/user/summary.component";
import ActionAndTask from "./components/assessments/user/actionAndTask.component";
import Companies from "./components/companies/companies.component";
import CompanyForm from "./components/companies/companyForm.component";
import SignUp from "./components/portal/signup.component";
import Roles from "./components/roles/roles.component";
import RoleForm from "./components/roles/roleForm.component";
import AssessmentType from "./components/assessments/admin/assessmentType.component";
import NoviCallback from "./components/portal/noviCallback.component";
import AssessmentTypeForm from "./components/assessments/admin/assessmentTypeForm.component";
import { getLoggedInUser } from "./store/auth/auth.slice";
import { useSyncSession } from "./hooks/syncSession.hook";
import Profile from "./components/profile/profile.component";
import Success from "./components/assessments/user/success.component";
import PastAssessments from "./components/assessments/user/pastAssessments.component";
import EpubViewer from "./components/info/epub.component";

// Custom hook for auth
const useAuth = () => {
  const session = useSelector((state: RootState) => state.auth.session);
  const role = useSelector((state: RootState) => state.role.role);
  return { session, role };
};

// ProtectedRoute for role-based access control
interface ProtectedRouteProps {
  children: JSX.Element;
  allowedRoles: string[];
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
  children,
  allowedRoles,
}) => {
  const { session, role } = useAuth();

  if (!session) {
    return <Navigate to="/login" replace />;
  }

  if (!role) {
    // TODO: add a loading screen
    return <div>Loading...</div>; // Or any loading component you prefer
  }

  if (!allowedRoles.includes(role?.name as string)) {
    // TODO: build unauthorized page
    return <Navigate to="/unauthorized" replace />;
  }

  return children;
};

const App: React.FC = () => {
  const session = useSelector((state: RootState) => state.auth.session);
  const dispatch = useDispatch<AppDispatch>();

  useSyncSession();

  //TODO: consider creating a hook for this
  useEffect(() => {
    if (session?.user_id) {
      dispatch(getLoggedInUser(session?.user_id));
    }
  }, [session, dispatch]);

  return (
    <div className="App">
      <Routes>
        <Route
          path="/"
          element={
            <ProtectedRoute allowedRoles={["Administrator", "Employee"]}>
              <Home />
            </ProtectedRoute>
          }
        />
        <Route
          path="/epub"
          element={
            <ProtectedRoute allowedRoles={["Administrator", "Employee"]}>
              <EpubViewer />
            </ProtectedRoute>
          }
        />
        <Route
          path="/assessment/category"
          element={
            <ProtectedRoute allowedRoles={["Administrator", "Employee"]}>
              <ChooseCategory />
            </ProtectedRoute>
          }
        />
        <Route
          path="/assessment/summary"
          element={
            <ProtectedRoute allowedRoles={["Administrator", "Employee"]}>
              <Summary />
            </ProtectedRoute>
          }
        />
        <Route
          path="/assessment/success"
          element={
            <ProtectedRoute allowedRoles={["Administrator", "Employee"]}>
              <Success />
            </ProtectedRoute>
          }
        />
        <Route
          path="/assessment/summary/action-and-task"
          element={
            <ProtectedRoute allowedRoles={["Administrator", "Employee"]}>
              <ActionAndTask />
            </ProtectedRoute>
          }
        />
        <Route
          path="/assessment/category/take"
          element={
            <ProtectedRoute allowedRoles={["Administrator", "Employee"]}>
              <Question />
            </ProtectedRoute>
          }
        />
        <Route
          path="/past-assessments"
          element={
            <ProtectedRoute allowedRoles={["Administrator", "Employee"]}>
              <PastAssessments />
            </ProtectedRoute>
          }
        />
        <Route
          path="/assessment-type"
          element={
            <ProtectedRoute allowedRoles={["Administrator", "Employee"]}>
              <AssessmentType />
            </ProtectedRoute>
          }
        />
        <Route
          path="/assessment-type/form"
          element={
            <ProtectedRoute allowedRoles={["Administrator"]}>
              <AssessmentTypeForm />
            </ProtectedRoute>
          }
        />
        <Route
          path="/company"
          element={
            <ProtectedRoute allowedRoles={["Administrator"]}>
              <Companies />
            </ProtectedRoute>
          }
        />
        <Route
          path="/company/form"
          element={
            <ProtectedRoute allowedRoles={["Administrator"]}>
              <CompanyForm />
            </ProtectedRoute>
          }
        />
        <Route
          path="/profile"
          element={
            <ProtectedRoute allowedRoles={["Administrator", "Employee"]}>
              <Profile />
            </ProtectedRoute>
          }
        />
        <Route
          path="/role"
          element={
            <ProtectedRoute allowedRoles={["Administrator"]}>
              <Roles />
            </ProtectedRoute>
          }
        />
        <Route
          path="/role/form"
          element={
            <ProtectedRoute allowedRoles={["Administrator"]}>
              <RoleForm />
            </ProtectedRoute>
          }
        />
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/auth/callback" element={<NoviCallback />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
      {/* <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.tsx</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
      </header> */}
    </div>
  );
};

export default App;
